export const tooltipCurYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">
                    {tooltip.month} <span class="bold">{tooltip.period}{tooltip.curYear}</span>
                 </td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Providerleistungen</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curProviderFees}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Drittanbieterleistungen</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curThirdPartyFees}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Tarifoptionen</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curTarrif}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title pt-10">
                    <span>Gesamtkosten</span>
                 </td>
                 <td align="right" class="pt-10">
                    <span>{tooltip.curTotal}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummern</span>
                 </td>
                 <td align="right">
                    <span>{tooltip.curPhoneNumbers}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Kosten i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curAverage}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
export const tooltipPrevYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">
                    <span>{tooltip.period}{tooltip.month} </span><span class="bold">{tooltip.prevYear}</span>
                 </td>
                 <td align="right">
                    <span>{tooltip.period}{tooltip.month} </span><span class="bold">{tooltip.curYear}</span>
                 </td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Providerleistungen</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <span>{tooltip.prevProviderFees}</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curProviderFees}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Drittanbieterleistungen</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <span>{tooltip.prevThirdPartyFees}</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curThirdPartyFees}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Tarifoptionen</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <span>{tooltip.prevTarrif}</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curTarrif}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title pt-10">
                    <span>Gesamtkosten</span>
                 </td>
                 <td align="right" class="pt-10">
                    <div class="pl-20">
                       <span>{tooltip.prevTotal}</span>
                    </div>
                 </td>
                 <td align="right" class="pt-10">
                    <div class="pl-20">
                       <span>{tooltip.curTotal}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevPhoneNumbers}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curPhoneNumbers}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Kosten i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevAverage}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curAverage}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
