import React, { useMemo } from 'react';
import { StateInterface } from '../../UserContext';
import * as am4core from '@amcharts/amcharts4/core';
import { Colors, Month, tooltipStyleObject } from '../../am4charts';
import * as am4charts from '@amcharts/amcharts4/charts';
import API from '../../API';
import { AxiosResponse } from 'axios';
import { tooltipCurYear, tooltipPrevYear } from './W12Tooltips';
import { formatISODate } from '../../utils';

interface IDisplayDate {
  month: number
  prevYear: number
  year: number
}

interface IW12Resp{
  amount: number
  year: number
  month: number
  options: Array<{
    name: string
    count: number
  }>
  date?: Date
  tooltip?: ITooltip
}

interface ITooltip {
  [propName: string]: number | string | Array<any>
}
interface IW10Resp {
  active: number
  loan: number
  lastRun: string
}

interface IWidgetData{
  curYear: Array<IW12Resp>
  prevYear: Array<IW12Resp>
}

interface IServerResponse {
  data: Array<IW12Resp>
}

let WidgetData: IWidgetData = {
  curYear: [],
  prevYear: []
}

type SetDisplayDate = (displayDate: IDisplayDate) => void

const fetchWidgetData = async (context: StateInterface, checked: boolean, tab: number): Promise<Array<IW12Resp>> => {
  const url = `/providers/${context.provider}/w12/?previousYear=${checked}${context.filter}&added=${tab === 0?'true':'false'}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  setFooterTitle: (footerTitle: string) => void,
  tab: number
) => {
  checkChangeContext(changeContext)
    try {
      let data: IW10Resp = await API.get('/w10').then((resp: AxiosResponse<IServerResponse>) => resp.data.data) as IW10Resp
      setFooterTitle(`Stand: ${formatISODate(data.lastRun)}`)
      const [curYear, prevYear]: Array<Array<IW12Resp>> = await Promise.all([
        fetchWidgetData(context, false, tab),
        fetchWidgetData(context, true, tab),
      ])
      WidgetData.curYear = curYear
      WidgetData.prevYear = prevYear
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  return WidgetData
}

const checkChangeContext = (changeContext: boolean) => {
  if (changeContext) {
    WidgetData = {
      curYear: [],
      prevYear: [],
    }
  }
}

const generateTooltip = (prevYearData: Array<IW12Resp>, curYearData: Array<IW12Resp>, i: number): ITooltip => ({
  month: Month[Number(prevYearData[i].month)],
  prevYear: String(prevYearData[i].year),
  curYear: String(curYearData[i].year),
  prevHtml: generateTooltipHTML(prevYearData[i], curYearData[i], true),
  curHtml: generateTooltipHTML(prevYearData[i], curYearData[i], false)
})

const getCount = (prevYearData: IW12Resp, key: string): number => {
  return (prevYearData.options || []).find(o => o.name === key)?.count || 0
}

const generateTooltipHTML = (prevYearData: IW12Resp, curYearData: IW12Resp, prevYear: boolean = false) => {
  const keys = Array.from(new Set([...(prevYear ? (prevYearData.options || []).map(o => o.name) : []), ...(curYearData.options || []).map(o => o.name)]))
  let html = ''
  for(const key of keys) {
    html += `<tr>
                 <td class="tooltip-title">
                    <span>${key}</span>
                 </td>
                 ${prevYear ? `<td align="right" style="color: #d9399d">
                    <div class="pl-20">
                       <span>${getCount(prevYearData, key)}</span>
                    </div>
                 </td>` : ''}
                 <td align="right" style="color: #007cc8">
                    <div class="pl-20">
                       <span>${getCount(curYearData, key)}</span>
                    </div>
                 </td>
              </tr>`
  }
  if(html === '') return '<tr><td>Keine Daten vorhanden</td></tr>'
  return html
}


const generateYearData = () => {
  const prevYearData: Array<IW12Resp> = []
  const curYearData: Array<IW12Resp> = []

  for (let i = 0; i < WidgetData.prevYear.length; i++) {
    prevYearData[i] = WidgetData.prevYear[i]
    prevYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
    prevYearData[i].date = new Date(WidgetData.curYear[i].year, Number(WidgetData.prevYear[i].month))

    if (WidgetData.curYear[i]) {
      curYearData[i] = WidgetData.curYear[i]
      curYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
      curYearData[i].date = new Date(WidgetData.curYear[i].year, Number(WidgetData.curYear[i].month))
    }
  }

  return {prevYearData, curYearData}
}


export const W12ChartInit = async (
  chartId: string,
  checked: boolean,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  displayDate: IDisplayDate,
  setFooterTitle: (footerTitle: string) => void,
  tab: number
): Promise<am4core.Sprite> => {
  await getData(context, setContext, changeContext, setFooterTitle, tab)
  const {prevYearData, curYearData} = generateYearData()

  const series = [
    {
      type: 'ColumnSeries',
      data: prevYearData,
      legendSettings: {
        labelText: String(displayDate.prevYear),
      },
      dataFields: {
        valueY: 'amount',
        dateX: 'date',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: tooltipPrevYear,
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      legendSettings: {
        labelText: displayDate.year,
      },
      dataFields: {
        valueY: 'amount',
        dateX: 'date',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
  ]

  return am4core.createFromConfig({
      data: checked ? prevYearData : curYearData,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
          },
          startLocation: 0,
          endLocation: 0.48,
        },
      ],
      yAxes: [
        {
          cursorTooltipEnabled: false,
          type: 'ValueAxis',
          min: 0,
          numberFormatter: {
            numberFormat: '#,###.##  St.',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series: checked ? [...series] : [series[1]],
    },
    chartId,
    am4charts.XYChart
  )
}
