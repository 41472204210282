import React from 'react'
import ReactDropdown, { Option } from 'react-dropdown'
import 'react-dropdown/style.css'

interface IProps {
  placeholderClassName?: string
  options: Array<ISelect>
  defaultOption: ISelect
  onChange: (index: Option) => void
}

export interface ISelect {
  value: string
  label: string
  className?: string
}

const Dropdown: React.FC<IProps> = ({ options, defaultOption, onChange, placeholderClassName }: IProps) => (
  <ReactDropdown
    placeholderClassName={placeholderClassName}
    options={options}
    onChange={onChange}
    value={defaultOption}
  />
)

export default Dropdown
