import React, {FC} from 'react'
import Widgets from '../Widgets/Widgets'
import FilterRow from '../FilterRow/FilterRow'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

const Dashboard: FC = () => {
  return (
    <div className="dashboard">
      <FilterRow />
      <div className="widgets-row">
        <Widgets />
      </div>
      <ErrorMessage />
    </div>
  )
}

export default Dashboard
