import React, { useContext, useEffect, useState } from 'react'
import {
  getCurYear,
  getPrevYear,
  Month,
} from '../../am4charts'
import Widget from '../Widget/Widget'
import { UserContext } from '../../UserContext'
import { W2ChartInit } from './W2ChartService'

const W2: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [displayDate, setDisplayDate] = useState({ month: 0, year: getCurYear(), prevYear: getPrevYear() })
  const [tab, setTab] = useState(0)
  const [context, setContext] = useContext(UserContext)

  const title = 'Wer verbraucht aktuell wie viel?'
  const chartId = 'W2'
  const switchersTitle = ['Kosten', 'Daten', 'kein Verbrauch']

  const exportLink = `/providers/${context.provider}/w2/export`

  const onChangeCheckbox = ({ target }: any) => {
    setChecked(target.checked)
  }

  const onSwitchTab = (index: number) => {
    setTab(index)
  }

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W2ChartInit(chartId, tab, checked, context, setContext, false, displayDate, setDisplayDate).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [tab, checked, displayDate])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W2ChartInit(chartId, tab, checked, context, setContext, true, displayDate, setDisplayDate).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [context])

  return (
    <Widget
      title={title}
      chartId={chartId}
      onChangeCheckbox={onChangeCheckbox}
      checkedCheckbox={checked}
      switchersTitle={switchersTitle}
      onSwitchTab={onSwitchTab}
      activeTab={tab}
      footerTitle={`${Month[displayDate.month]} ${displayDate.year}`}
      chartHeight={'306px'}
      showLegend={checked && tab === 2}
      loading={loading}
      exportLink={exportLink}
    />
  )
}

export default W2
