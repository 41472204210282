import React, { useContext, useEffect, useState } from 'react';
import { getCurYear, getPrevYear, Month } from '../../am4charts';
import Widget from '../Widget/Widget';
import { UserContext } from '../../UserContext';
import { W12ChartInit } from './W12ChartService';

interface IW11 {

}

const W12: React.FC = ({}: IW11) => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [footerTitle, setFooterTitle] = useState('')
  const [tab, setTab] = useState(0)
  const [displayDate, setDisplayDate] = useState({ month: 0, year: getCurYear(), prevYear: getPrevYear() })
  const [context, setContext] = useContext(UserContext)

  const title = 'Tarifoptionen (neu gebucht/gelöscht)'
  const chartId = 'W12'
  const switchersTitle = ['neu gebucht', 'gelöscht']
  const exportLink = `/providers/${context.provider}/w12/export`

  const onChangeCheckbox = ({target}: any) => {
    setChecked(target.checked)
  }

  const onSwitchTab = (index: number) => {
    setTab(index)
  }

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W12ChartInit(chartId, checked, context, setContext, false, displayDate, setFooterTitle, tab).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [tab, checked, displayDate])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W12ChartInit(chartId, checked, context, setContext, true, displayDate, setFooterTitle, tab).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [context])

  return (
    <Widget
      title={title}
      chartId={chartId}
      checkedCheckbox={checked}
      onChangeCheckbox={onChangeCheckbox}
      switchersTitle={switchersTitle}
      onSwitchTab={onSwitchTab}
      activeTab={tab}
      footerTitle={footerTitle}
      chartHeight={'267px'}
      loading={loading}
      notFiltering={true}
      exportLink={exportLink}
    />
  )
}

export default W12
