import React from 'react'
import {Option} from 'react-dropdown'
import {getCurYear, getPrevYear} from '../../am4charts'
import Chart from '../Chart/Chart'
import Checkbox from '../Checkbox/Checkbox'
import Dropdown from '../Dropdown/Dropdown'
import IconLink from '../IconLink/IconLink'
import Info from '../Info/Info'
import WidgetSwitcher from './WidgetSwitcher'

export interface IProps {
  title: string
  chartId: string
  chartHeight?: string
  switchersTitle?: Array<string>
  onChangeCheckbox?: ({target: {checked}}: any) => void
  checkedCheckbox?: boolean
  onSwitchTab?: (index: number) => void
  onSwitchRange?: (index: Option) => void
  activeTab?: number
  activeRange?: number
  footerTitle?: string
  showLegend?: boolean
  chartLegends?: Array<string>
  doubleLegend?: boolean
  onSwitchTraffic?: (index: Option) => void
  trafficOptions?: Array<IOptions>
  activeTraffic?: string
  loading?: boolean
  notFiltering?: boolean
  exportLink?: string
}

interface IOptions {
  value: string
  label: string
}

const options = [
  {value: '0', label: 'Jahresbericht'},
  {value: '1', label: 'Letztes Quartal'},
  {value: '2', label: 'Letzter Monat'},
]

const defaultTraffic = (trafficOptions: Array<IOptions>, activeTraffic: string): IOptions => {
  return trafficOptions.find((item: IOptions) => item.value === activeTraffic) as IOptions
}

const Widget: React.FC<IProps> = ({
  title,
  switchersTitle,
  chartId,
  chartHeight,
  onChangeCheckbox,
  checkedCheckbox,
  onSwitchTab,
  onSwitchRange,
  activeTab,
  activeRange,
  footerTitle,
  showLegend,
  chartLegends,
  doubleLegend,
  onSwitchTraffic,
  trafficOptions,
  activeTraffic,
  loading,
  notFiltering,
  exportLink,
}: IProps) => (
  <>
    <div className="widget-title">{title}</div>
    <div className="widget-container">
      {notFiltering && <Info icon={'!'} message={'von keiner Filterung betroffen'} />}
      {switchersTitle && onSwitchTab && typeof activeTab === 'number' && (
        <WidgetSwitcher activeTab={activeTab} onSwitchTab={onSwitchTab} switchersTitle={switchersTitle} />
      )}
      {onSwitchTraffic && trafficOptions && typeof activeTraffic === 'string' && (
        <div className="dropdown-wrapper">
          <label>Verkehrswege:</label>
          <Dropdown
            options={trafficOptions}
            defaultOption={defaultTraffic(trafficOptions, activeTraffic)}
            onChange={onSwitchTraffic}
          />
        </div>
      )}
      <Chart id={chartId} height={chartHeight} />
      {onChangeCheckbox && typeof checkedCheckbox === 'boolean' && (
        <Checkbox onChange={onChangeCheckbox} checked={checkedCheckbox} />
      )}
      {showLegend && (
        <div className={`chart-legends${doubleLegend ? ' double' : ''}`}>
          <div className="chart-legend">{(chartLegends && chartLegends[1]) || getPrevYear()}</div>
          <div className="chart-legend">{(chartLegends && chartLegends[0]) || getCurYear()}</div>
        </div>
      )}
      <div className="widget-footer">
        <div>
          {onSwitchRange && typeof activeRange === 'number' && (
            <Dropdown options={options} defaultOption={options[activeRange]} onChange={onSwitchRange} />
          )}
          {footerTitle && <div className="widget-footer__title">{footerTitle}</div>}
        </div>
        {exportLink && <IconLink downloadLink={exportLink} downloadText="Herunterladen (Ohne Filterung)" />}
      </div>
      {loading && <div className="loading sm"></div>}
    </div>
  </>
)

export default Widget
