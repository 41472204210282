import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.scss'

ReactDOM.render(<App />, document.getElementById('root'))

console.log(`Dashboard version: ${process.env.REACT_APP_VERSION || ''}`)
