import React from 'react'

interface IProps {
  id: string
  height?: string
}

const Chart: React.FC<IProps> = ({ height='330px', id }: IProps) => <div id={id} className="chart" style={{ height }}/>

export default Chart
