import axios from 'axios'

let sessionToken: string
export const setSessionToken = (token: string): string => (sessionToken = token)
export const getSessionToken = (): string => sessionToken || process.env.REACT_APP_X_SESSION_ID || ''

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: 'json',
})

API.interceptors.request.use(
  (config) => {
    config.headers['x-sessionid'] = getSessionToken()
    console.log(getSessionToken())
    return config
  },
  (error) => Promise.reject(error),
)

API.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export default API
