export const tooltipCurYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Aktiv</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="pl-50">
                       <span>{tooltip.curActiveContracts}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Gekündigt</span>
                 </td>
                 <td align="right" style="color: #4ca3d8">
                    <div class="pl-50">
                       <span>{tooltip.curTerminatingContracts}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Verlängerbar</span>
                 </td>
                 <td align="right" style="color: #bfdef1">
                    <div class="pl-50">
                       <span>{tooltip.curProlongableContracts}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
export const tooltipPrevYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Aktiv</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="pl-20">
                       <span>{tooltip.prevActiveContracts}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="pl-20">
                       <span>{tooltip.curActiveContracts}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Gekündigt</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevTerminatingContracts}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curTerminatingContracts}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Verlängerbar</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevProlongableContracts}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curProlongableContracts}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
