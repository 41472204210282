export function formatISODate(isoDate: string): string {
  try {
    const parsedISODate = new Date(isoDate).toISOString()
    const [date, time] = parsedISODate.split('T')
    const [year, month, day] = date.split('-')
    const [hour, minute, _] = time.split(':')

    return `${day}.${month}.${year} ${hour}:${minute}`
  } catch (error) {
    if (error instanceof RangeError) {
      return ''
    } else {
      throw error
    }
  }
}
