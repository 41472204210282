import React, {useContext, useEffect, useState} from 'react'
import {TreeNode} from 'react-dropdown-tree-select'
import Dropdown, {ISelect} from '../Dropdown/Dropdown'
import Filter from '../Filter/Filter'
import {UserContext} from '../../UserContext'
import API from '../../API'

export interface IFilter {
  value: string
  label: string
  expanded: boolean
  checked?: boolean
  children?: Array<IFilter>
}

interface IFilterResponse {
  customerNumber: string
  accountNumbers: Array<string>
}

interface ICurrFilter {
  customerNumbers: Array<string>
  accountNumbers: Array<string>
}

const FilterRow: React.FC = () => {
  const [providers, setProviders] = useState<Array<ISelect>>([])
  const [filters, setFilters] = useState<Array<IFilter>>([])
  const [context, setContext] = useContext(UserContext)

  useEffect(() => {
    void fetchProviders()
  }, [])

  useEffect(() => {
    if (providers.length) {
      setContext({provider: providers[0].value})
    }
  }, [providers])

  useEffect(() => {
    if (context.provider) {
      void fetchFilters()
    }
  }, [context.provider])

  const fetchProviders = async () => {
    try {
      const res: Array<ISelect> = await API.get('/providers').then((resp: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
        return resp.data.providers.map((item: string) => ({
          value: item,
          label: item,
          className: item.toLowerCase(),
        }))
      })
      setProviders(res)
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }

  const fetchFilters = async () => {
    try {
      const res: Array<IFilter> = await API.get(`/providers/${context.provider}/clients`).then((resp: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
        return resp.data.clients.map((item: IFilterResponse) => ({
          value: item.customerNumber,
          label: item.customerNumber,
          expanded: true,
          checked: false,
          children: item.accountNumbers
            .filter((child: string) => child !== item.customerNumber)
            .map((filteredChild: string) => ({
              value: filteredChild,
              label: filteredChild,
              checked: false,
            })),
        }))
      })
      setFilters(res)
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }

  const generateString = (arr: ICurrFilter): string => {
    let customersString: string = ''
    let accountsString: string = ''
    arr.customerNumbers.map((cus: any, index: number) => {
      return (customersString = customersString.concat(`&customers[${index}]=${cus}`))
    })
    arr.accountNumbers.map((acc: any, index: number) => {
      return (accountsString = accountsString.concat(`&accounts[${index}]=${acc}`))
    })
    setContext({filter: customersString + accountsString})
    return customersString + accountsString
  }

  const changeFilter = (currentNode: TreeNode, selectedNodes: Array<TreeNode>) => {
    const currFilter: ICurrFilter = {
      customerNumbers: [],
      accountNumbers: [],
    }
    const arr = selectedNodes.map((selectedItem: any) => {
      if (selectedItem._parent) {
        return currFilter.accountNumbers.push(selectedItem.value)
      } else {
        return currFilter.customerNumbers.push(selectedItem.value)
      }
    })
    parseFilters(filters, currentNode)
    generateString(currFilter)
    return arr
  }

  const parseFilters = (arr: Array<IFilter>, curr: TreeNode, parent?: IFilter) => {
    arr.forEach((el: IFilter) => {
      if (el.value === curr.value) {
        el.checked = curr.checked
        if (el.children) {
          el.children.forEach((child: any) => {
            child.checked = curr.checked
          })
        }
        if (parent && !curr.checked) {
          parent.checked = false
        }
      } else {
        if (el.children) {
          parseFilters(el.children, curr, el)
        }
      }
    })
  }

  return (
    <div className="filter-row">
      <div className="dropdown-wrapper">
        <label>Carrier:</label>
        <Dropdown
          placeholderClassName={context.provider.toLowerCase()}
          options={providers}
          defaultOption={{value: context.provider, label: context.provider, className: context.provider}}
          onChange={(obj) => {
            setContext({provider: obj.value, filter: ''})
          }}
        />
      </div>
      <div className="dropdown-wrapper">
        <label>Filtern:</label>
        <Filter options={filters} onChange={changeFilter} />
      </div>
    </div>
  )
}

export default FilterRow
