export const tooltipCountry = (range: 0|1|2, primary: number, secondary?: string): string => `
<table class="tooltip">
    <thead>
      <tr>
          <td align="left">{name}</td>
          <td align="right">
            ${secondary}
            ${range !== 1 ? `<strong>${primary}</strong>` : ''}
          </td>
      </tr>
    </thead>
    <tbody>
      <tr>
          <td class="tooltip-title">
            <span>Aktive Verträge</span>
          </td>
          <td align="right">
            <span><strong>{amount} St.</strong></span>
          </td>
      </tr>
    </tbody>
</table>`
