import React from 'react'

interface IProps {
  icon: string
  message: string
}

const Info: React.FC<IProps> = ({icon, message}: IProps) => (
  <div className="info">
    <div className="info-icon">{icon}</div>
    <div className="info-message">{message}</div>
  </div>
)

export default Info
