export const tooltipCurCosts = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Verbrauchskosten</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curTotal}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummern</span>
                 </td>
                 <td align="right">
                    <span>{tooltip.curUsers}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Verbrauch i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curAvgPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
export const tooltipCurData = `
                <table class="tooltip">
                   <thead>
                      <tr>
                         <td align="left">{tooltip.month} <span class="bold">{tooltip.year}</span></td>
                         <td align="right"><div class="pl-20">International</div></td>
                         <td align="right"><div class="pl-20">National</div></td>
                      </tr>
                   </thead>
                   <tbody>
                      <tr>
                         <td class="tooltip-title">
                            <span>Datenverbrauch</span>
                         </td>
                         <td align="right" style="color: #007cc8">
                            <span>{tooltip.intTotal}</span>
                         </td>
                         <td align="right" style="color: #bfdef1">
                            <span>{tooltip.natTotal}</span>
                         </td>
                      </tr>
                      <tr>
                         <td class="tooltip-title">
                            <span>Rufnummern</span>
                         </td>
                         <td align="right">
                            <span>{tooltip.intUsers}</span>
                         </td>
                         <td align="right">
                            <span>{tooltip.natUsers}</span>
                         </td>
                      </tr>
                      <tr>
                         <td class="tooltip-title">
                            <span>Verbrauch i. D. p. Rufnummer</span>
                         </td>
                         <td align="right">
                            <span>{tooltip.intAvgPerUser}</span>
                         </td>
                         <td align="right">
                            <span>{tooltip.natAvgPerUser}</span>
                         </td>
                      </tr>
                   </tbody>
                </table>`
export const tooltipPrev = (label: 'Verbrauchskosten' | 'Datenverbrauch'): string => `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>${label}</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="pl-20">
                       <span>{tooltip.prevTotal}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="pl-20">
                       <span>{tooltip.curTotal}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummern</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevUsers}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curUsers}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Verbrauch i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevAvgPerUser}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curAvgPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
