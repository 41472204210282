export const tooltipCurYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td><span class="bold pr-60">{tooltip.traffic}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Dauer</span>
                 </td>
                 <td align="right" style="color: {tooltip.curDurationColor}">
                    <span>{tooltip.curDuration}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Allowance</span>
                 </td>
                 <td align="right">
                    <span>{tooltip.curAllowance}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Toleranz</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span class="plus-minus">±</span><span>{tooltip.curTolerance}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummern</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curPhoneNumbers}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
export const tooltipPrevYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td><span class="bold pr-50">{tooltip.traffic}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="right">
                    <div class="pl-50">{tooltip.month} <span class="bold">{tooltip.curYear}</div></span>
                 </td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Dauer</span>
                 </td>
                 <td align="right" style="color: {tooltip.prevDurationColor}">
                    <div class="pl-20">
                       <span>{tooltip.prevDuration}</span>
                    </div>
                 </td>
                 <td align="right" style="color: {tooltip.curDurationColor}">
                    <div class="pl-20">
                       <span>{tooltip.curDuration}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Allowance</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <span>{tooltip.prevAllowance}</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curAllowance}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Toleranz</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span class="plus-minus">±</span><span>{tooltip.prevTolerance}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span class="plus-minus">±</span><span>{tooltip.curTolerance}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummern</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevPhoneNumbers}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curPhoneNumbers}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
