import React from 'react'
import API from '../../API'

interface IProps {
  downloadLink?: string
  downloadText?: string
}

const download = async (downloadLink: string | undefined) => {
  if (typeof(downloadLink) !== 'string') {
    return
  }

  await API.get(
    downloadLink,
    { responseType: 'blob' },
  ).then((response) => {
    const contentDisposition: string = response.headers['content-disposition']
    const contentType: string = response.headers['content-type']

    const blob = new Blob([response.data], {type: contentType})
    // Expects non-quoted filename
    const filename = contentDisposition.split('filename=')[1]

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  })
}

const IconLink: React.FC<IProps> = ({ downloadLink, downloadText }: IProps) => (
  <div className="icon-link" onClick={() => download(downloadLink)}>
    <span className="download"/>
    { downloadText }
  </div>
)

export default IconLink
