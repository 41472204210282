import React from 'react'

export interface ISwitcherProps {
  switchersTitle: Array<string>
  onSwitchTab: (index: number) => void
  activeTab: number
  className?: string
}

const WidgetSwitcher: React.FC<ISwitcherProps> = ({
  switchersTitle,
  onSwitchTab,
  activeTab,
  className,
}: ISwitcherProps) => (
  <div className={['widget-switcher', className].join(' ')}>
    {switchersTitle.map((title, index) => (
      <div onClick={() => onSwitchTab(index)} className={activeTab === index ? 'active' : ''} key={title}>
        {title}
      </div>
    ))}
  </div>
)

export default WidgetSwitcher
