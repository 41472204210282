export const tooltipCurYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              {tooltip.curHtml}
           </tbody>
        </table>`

export const tooltipPrevYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              {tooltip.prevHtml}
           </tbody>
        </table>`
