import { useEffect } from 'react'

const useEvent = (event: string, handler: EventListener, passive=false): void => {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive)

    // this will clean up the event every time the component is re-rendered
    return () => window.removeEventListener(event, handler)
  })
}
export default useEvent
