import React, {useContext, useEffect, useState} from 'react'
import DropdownTreeSelect, {TreeNode} from 'react-dropdown-tree-select'
import {UserContext} from '../../UserContext'
import {IFilter} from '../FilterRow/FilterRow'

interface IProps {
  options: Array<IFilter>
  onChange: any
}

const Filter: React.FC<IProps> = ({options, onChange}: IProps) => {
  const defaultTitle = 'Kundennummer'
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(defaultTitle)
  const [context] = useContext(UserContext)

  useEffect(() => {
    if (!context.filter) {
      setTitle(defaultTitle)
    }
  }, [context.filter])

  const show = () => {
    setIsDisplayed((value) => !value)
  }

  const onFilterChange = (currentNode: TreeNode, selectedNodes: Array<TreeNode>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    onChange(currentNode, selectedNodes)

    switch (selectedNodes.length) {
      case 0: {
        setTitle(defaultTitle)
        break
      }
      case 1: {
        setTitle(selectedNodes[0].label)
        break
      }
      default: {
        setTitle('Mehrfachauswahl')
        break
      }
    }
  }

  return (
    <div className="react-dropdown-tree-select-wrap">
      <div className={`react-dropdown-tree-select-title ${isDisplayed ? 'displayed' : ''}`} onClick={show}>
        {title}
      </div>
      <DropdownTreeSelect
        data={options}
        className={isDisplayed ? '' : 'hidden'}
        onChange={onFilterChange}
        showDropdown="always"
        showPartiallySelected={true}
        texts={{placeholder: 'Suche'}}
      />
    </div>
  )
}

export default Filter
