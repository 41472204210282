import React, {useState} from 'react'
import useEvent from './session'
import Dashboard from './components/Dashboard/Dashboard'
import UserContextProvider from './UserContext'
import {setSessionToken} from './API'

const App: React.FC<unknown> = () => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const dashboard = (
    <UserContextProvider>
      <Dashboard/>
    </UserContextProvider>
  )

  useEvent('message', (event: any) => {
    setLoading(true)

    console.log('event.origin', event.origin)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.log('REACT_APP', window.REACT_APP_IFRAME_PARENT_ORIGIN)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    //todo: reimplement again
    // if (event.origin !== window.REACT_APP_IFRAME_PARENT_ORIGIN) {
    //   setError(true)
    // }

    console.log('event.data.session', event.data.session)
    setSessionToken(event.data.session)
    setLoading(false)
  })

  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return dashboard
  }

  if (loading) {
    return <div className="loading"/>
  }

  if (error) {
    return <div className="error-session">Error: invalid parent origin</div>
  }

  return dashboard
}

export default App
