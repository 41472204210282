import React from 'react'

interface IProps {
  checked: boolean
  onChange: ({ target: { checked } }: any) => void
}

const Checkbox: React.FC<IProps> = ({ checked, onChange }: IProps) => (
  <label className="checkbox-item">
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span>Vorjahr</span>
  </label>
)

export default Checkbox
