export const tooltipCurYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Nachbuchungen</span>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <span>{tooltip.curAdditionalDataPackages}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummer</span>
                 </td>
                 <td align="right">
                    <span>{tooltip.curPhoneNumbers}</span>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Buchungen i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curIndex}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
export const tooltipPrevYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td colspan="2" align="right">{tooltip.month} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="right">{tooltip.month} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <span>Nachbuchungen</span>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="pl-20">
                       <span>{tooltip.prevAdditionalDataPackages}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="pl-20">
                       <span>{tooltip.curAdditionalDataPackages}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevPhoneNumbers}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curPhoneNumbers}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <span>Buchungen i. D. p. Rufnummer</span>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.prevIndex}</span>
                    </div>
                 </td>
                 <td align="right">
                    <div class="pl-20">
                       <span>{tooltip.curIndex}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`
