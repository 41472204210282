import React, {useState, useEffect, useContext} from 'react'
import { Option } from 'react-dropdown'
import Widget from '../Widget/Widget'
import { UserContext } from '../../UserContext'
import { W8ChartInit } from './W8ChartService'

const W8: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [previousYear, setPreviousYear] = useState(false)
  const [range, setRange] = useState(0)
  const [tab, setTab] = useState(0)
  const [context, setContext] = useContext(UserContext)
  const title = 'Wo werden die Verträge genutzt?'
  const chartId = 'W8'

  const onSwitchTab = (index: number) => {
    setTab(index)
  }

  const onSwitchRange = (index: Option) => {
    setRange(+index.value)
  }

  const onChangeCheckbox = ({ target }: any) => {
    setPreviousYear(target.checked)
  }

  useEffect(() => {
    setLoading(true)
    let dispose = () => {''}

    void W8ChartInit(chartId, previousYear, range, context, setContext).then((chart) => {
      setLoading(false)
      dispose = () => chart.dispose()
    })
    return () => dispose()
  }, [tab, range, previousYear])
  return (
    <Widget
      title={title}
      chartId={chartId}
      activeTab={tab}
      loading={loading}
      activeRange={range}
      onSwitchTab={onSwitchTab}
      onSwitchRange={onSwitchRange}
      onChangeCheckbox={onChangeCheckbox}
      checkedCheckbox={previousYear}
    />
  )
}

export default W8
