import React, { useContext, useEffect, useState } from 'react';
import { getCurYear, getPrevYear, Month } from '../../am4charts';
import Widget from '../Widget/Widget';
import { UserContext } from '../../UserContext';
import { W11ChartInit } from './W11ChartService';

interface IW11 {

}

const W11: React.FC = ({}: IW11) => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [footerTitle, setFooterTitle] = useState('')
  const [displayDate, setDisplayDate] = useState({ month: 0, year: getCurYear(), prevYear: getPrevYear() })
  const [context, setContext] = useContext(UserContext)

  const title = 'Aktive Verträge pro Monat mit gebuchten Tarifoptionen'
  const chartId = 'W11'
  const exportLink = `/providers/${context.provider}/w11/export`

  const onChangeCheckbox = ({target}: any) => {
    setChecked(target.checked)
  }

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W11ChartInit(chartId, checked, context, setContext, false, displayDate, setFooterTitle).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [checked, displayDate])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W11ChartInit(chartId, checked, context, setContext, true, displayDate, setFooterTitle).then(chart => {
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [context])

  return (
    <Widget
      title={title}
      chartId={chartId}
      checkedCheckbox={checked}
      onChangeCheckbox={onChangeCheckbox}
      footerTitle={footerTitle}
      chartHeight={'293px'}
      loading={loading}
      notFiltering={true}
      exportLink={exportLink}
    />
  )
}

export default W11
